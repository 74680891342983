/* Centering the form */
.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f7f6; /* Light gray background */
}

/* Styling the form */
.signup-box {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
}

/* Heading */
.signup-box h2 {
    margin-bottom: 1rem;
    color: #333;
}

/* Input fields */
.signup-box input {
    width: 100%;
    padding: 10px;
    margin: 8px 0;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 16px;
}

/* Button */
.signup-box button {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
}

.signup-box button:hover {
    background-color: #0056b3;
}

/* Message */
.message {
    margin-top: 10px;
    color: #d9534f;
}

/* Responsive */
@media (max-width: 480px) {
    .signup-box {
        width: 90%;
        padding: 1.5rem;
    }
}

/* Forgot Password Link */
.forgot-password {
    margin-top: 10px;
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
    font-size: 14px;
}

.forgot-password:hover {
    color: #0056b3;
}

/* Back to Sign In Button */
.back-button {
    margin-top: 10px;
    background-color: #6c757d;
    color: white;
    border: none;
    padding: 10px;
    width: 100%;
    border-radius: 6px;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
}

.back-button:hover {
    background-color: #5a6268;
}

