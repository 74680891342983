.container {
    font-family: Arial, sans-serif;
    background-color: #f4f6f9;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Navbar */
.navbar {
    width: 100%;
    background-color: #007bff;
    padding: 15px 20px;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.nav-content {
    display: flex;
    justify-content: space-between;
    width: 90%;
    max-width: 1200px;
    align-items: center;
}

.brand {
    color: white;
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
}

.logout-button {
    background-color: white;
    color: #007bff;
    border: none;
    padding: 8px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s ease;
}

.logout-button:hover {
    background-color: #e0e0e0;
}

/* Dashboard layout */
.dashboard-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100vh;
    width: 100%;
    padding-top: 70px;
    padding-left: 5%;
    padding-right: 5%;
}

.dashboard-box {
    background: white;
    padding: 3rem;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 900px;
    text-align: center;
}

.file-upload {
    border: 2px dashed #007bff;
    padding: 20px;
    border-radius: 8px;
    background: #f1f8ff;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.file-upload:hover {
    background: #e2eefd;
}

.file-upload input {
    display: none;
}

.file-upload span {
    font-size: 16px;
    color: #007bff;
}

/* Upload Button */
.upload-button {
    width: 100%;
    padding: 10px;
    margin-top: 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
}

.upload-button:hover {
    background-color: #0056b3;
}

/* Table Styling */
.file-table {
    width: 100%;
    margin-top: 30px;
    border-collapse: collapse;
}

.file-table th, .file-table td {
    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
}

.file-table th {
    background-color: #f1f8ff;
    color: #007bff;
}

.file-table td {
    font-size: 14px;
}

.fine-tune-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 6px 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.fine-tune-button:hover {
    background-color: #218838;
}

.upload-message {
    margin-top: 10px;
    color: green;
}
